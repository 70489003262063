<template>
  <div id="message_modal_user" class="uk-flex-top" uk-modal>
    <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
      <div class="uk-modal-header pl-0">
        <h2 class="uk-modal-title">Mesaj Gönder</h2>
        <button class="uk-modal-close-default" type="button" uk-close></button>

        <div
            class="uk-alert-success bg-success-700 h-25px"
            uk-alert
            v-if="successMessageSend"
        >
          <a class="uk-alert-close" uk-close></a>
          <p style="color: green">
            <i
                class="fa fa-check d-inline-flex p-2"
                aria-hidden="true"
            ></i
            >{{ successMessageSend }}
          </p>
        </div>

      </div>
      <div class="uk-modal-body pl-0 pr-0">
                                        <textarea
                                            name="content"
                                            id="content_message"
                                            class="form-control"
                                            v-model="form.content"
                                            placeholder="Mesajınız"
                                        ></textarea>
      </div>
      <div class="uk-modal-footer pl-0 pr-0 d-flex justify-content-end">
        <button
            class="uk-button uk-button-primary small"
            type="button"
            @click="sendMessage()"
        >
          <i class="icon-feather-arrow-right"></i> Gönder
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "UserProfile",
  data() {
    return {
      modalName: 'message_modal_user',
      successMessageSend: null,
      form: {
        content: "",
      },
    };
  },
  props: {
    userId: {
      type: Number,
      required: true
    },
  },
  methods: {
    sendMessage() {
      let self = this;
      let form = self.form;
      let user_id = this.userId;
      let sender_user_id = this.authUser.id;
      let formData = new FormData();
      formData.append("message", form.content);
      formData.append("sender_user_id", sender_user_id);

      let groupFormData = new FormData();
      groupFormData.append("users[]", [user_id]);

      axios.post('api/store-group',
          groupFormData, {
            headers: self.headers
          })
          .then(function (response) {
            formData.append("message_group_id", response.data.id);
            axios.post('api/send-message',
                formData, {
                  headers: self.headers
                })
                .then((response) => {
                  self.successMessageSend = "Mesaj gönderildi";

                  setTimeout(() => {
                    self.successMessageSend = null;
                    self.form.content = null;
                    UIkit.modal("#" + self.modalName).$destroy();
                  }, 500);
                })
          })
    },
    closeModal() {
      const modal = UIkit.modal("#" + this.modalName);
      if (modal) modal.$destroy(true);
    }
  },
  mounted() {
    this.form.content = null;
    this.successMessageSend = null;
    const modal = UIkit.modal("#" + this.modalName);
    if (modal) modal.show();
  },
};
</script>
