<template>
  <div id="modal-profile" class="uk-modal-full" uk-modal>
    <div class="uk-modal-footer uk-text-right bggray">
      <button class="uk-button uk-button-default small uk-modal-close" type="button"><i class="uil-times"></i>
        {{ $t("general.close") }}</button>
    </div>
    <div class="uk-modal-dialog bggray" v-if="userData">
      <div class="uk-padding-large position-relative" uk-grid>
        <div class="uk-width-1-5@m">
          <div class="uk-card-default rounded text-center p-4">
            <div class="user-profile-photo  m-auto">
              <img :src="userData.photo_link" alt="">
            </div>
            <h4 class="mb-4 mt-3">{{ userData.fullname }}<br> <span style="font-size:1rem;color:#a08383;">#{{
              userData.id
                }}</span></h4>
            <button type="button" @click.prevent="openMessageModal" class="btn btn-soft-primary mb-2 btn-block"><i
                class="uil-envelope-alt"></i>{{ $t('general.send_message') }}</button>
            <button v-if="socialPostVisible" type="button"
              @click.prevent="$router.push({ name: 'company.user.wall', params: { user: userData.id } })"
              class="btn btn-soft-primary btn-block">
              <i class="uil-coffee"></i> {{ $t('profile.look_wall') }}</button>
            <button type="button"
              @click.prevent="$router.push({ name: 'company.user.statistics', params: { user: userData.id } })"
              class="btn btn-soft-primary mb-2 btn-block"><i class="uil-chart-pie"></i> İstatistikler</button>
          </div>
          <div class="uk-card-default rounded mt-4">
            <div class="uk-flex uk-flex-between uk-flex-middle position-relative py-3 px-4">
              <h5 class="mb-0"><i class="icon-feather-bar-chart"></i> Profil Seviyesi</h5>
              <!--              <div class="profile-point uk-card-default">57</div>-->
            </div>
            <hr class="m-0">
            <div class="p-3">
              <!--              <div class="uk-grid-small uk-flex-middle" uk-grid>-->
              <!--                <div class="uk-width-auto">-->
              <!--                  <button type="button" class="btn btn-danger btn-icon-only">-->
              <!--               <span class="d-flex justify-content-center">-->
              <!--               <i class="icon-feather-battery-charging icon-small"></i>-->
              <!--               </span>-->
              <!--                  </button>-->
              <!--                </div>-->
              <!--                <div class="uk-width-expand">-->
              <!--                  <h5 class="mb-2">Güç: 50 </h5>-->
              <!--                  <div class="course-progressbar">-->
              <!--                    <div class="course-progressbar-filler" style="width:68%"></div>-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--              </div>-->
              <!--              <div class="uk-grid-small uk-flex-middle" uk-grid>-->
              <!--                <div class="uk-width-auto">-->
              <!--                  <button type="button" class="btn btn-warning btn-icon-only">-->
              <!--               <span class="d-flex justify-content-center">-->
              <!--               <i class="icon-feather-chevrons-right icon-small"></i>-->
              <!--               </span>-->
              <!--                  </button>-->
              <!--                </div>-->
              <!--                <div class="uk-width-expand">-->
              <!--                  <h5 class="mb-2"> Hız: 42 </h5>-->
              <!--                  <div class="course-progressbar">-->
              <!--                    <div class="course-progressbar-filler" style="width:42%"></div>-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--              </div>-->
              <!--              <div class="uk-grid-small uk-flex-middle" uk-grid>-->
              <!--                <div class="uk-width-auto">-->
              <!--                  <button type="button" class="btn btn-primary btn-icon-only">-->
              <!--               <span class="d-flex justify-content-center">-->
              <!--               <i class="uil-coffee icon-small"></i>-->
              <!--               </span>-->
              <!--                  </button>-->
              <!--                </div>-->
              <!--                <div class="uk-width-expand">-->
              <!--                  <h5 class="mb-2"> Sosyallik: 70</h5>-->
              <!--                  <div class="course-progressbar">-->
              <!--                    <div class="course-progressbar-filler" style="width:70%"></div>-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--              </div>-->
              <div class="uk-grid-small uk-flex-middle" uk-grid>
                <div class="uk-width-auto">
                  <button type="button" class="btn btn-info btn-icon-only">
                    <span class="d-flex justify-content-center">
                      <i class="icon-feather-award icon-small"></i>
                    </span>
                  </button>
                </div>
                <div class="uk-width-expand">
                  <h5 class="mb-2"> Sertifika: {{ certificatesCount }}</h5>
                </div>
              </div>
              <hr>
              <div class="uk-grid-small uk-flex-middle" uk-grid>
                <div class="uk-width-auto">
                  <button type="button" class="btn btn-success btn-icon-only">
                    <span class="d-flex justify-content-center">
                      <i class="uil-coins icon-small"></i>
                    </span>
                  </button>
                </div>
                <div class="uk-width-expand">
                  <h5 class="mb-2"> Kazanç: {{ userPoint }} Puan / {{ userCoin }} Coin</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="uk-width-2-5@m">
          <div class="section-header pb-0 mt-0 mb-3">
            <div class="section-header-left">
              <h4 class="uk-text-truncate"><i class="uil-chart "></i> Kullanıcı Hakkında
              </h4>
            </div>
          </div>
          <div class="uk-grid-small uk-child-width-1-3 uk-child-width-1-4@s uk-text-center mb-4" uk-grid>
            <div class="">
              <div class="course-card uk-card-hover foruser profile">
                <div class="course-card-body">
                  <h4>{{ userItem.assignments_count }}</h4>
                  <p>Eğitim<br>Atandı</p>
                </div>
              </div>
            </div>
            <div>
              <div class="course-card uk-card-hover foruser profile">
                <div class="course-card-body">
                  <h4>{{ userItem.completed_assignments_count }}</h4>
                  <p>Eğitimi<br>Tamamladı</p>
                </div>
              </div>
            </div>
            <div>
              <div class="course-card uk-card-hover foruser profile">
                <div class="course-card-body">
                  <h4>{{ giftsCount }}</h4>
                  <p>Hediye<br>Kazandı</p>
                </div>
              </div>
            </div>
            <div>
              <div class="course-card uk-card-hover foruser profile">
                <div class="course-card-body">
                  <h4>{{ userPoint }} / {{ userCoin }}</h4>
                  <p>Puan <br> Coin</p>
                </div>
              </div>
            </div>
          </div>
          <div class="uk-card-default rounded">
            <div class="uk-flex uk-flex-between uk-flex-middle py-3 px-4">
              <h5 class="mb-0"><i class="icon-feather-user"></i> Üyelik Bilgileri</h5>
              <span @click="closeModal" class="uk-modal-close" uk-tooltip="title: Bilgileri Güncelle; pos: left">
                <i class="icon-feather-settings"></i>
              </span>
            </div>
            <hr class="m-0">
            <div class="uk-child-width-1-2@s uk-grid-small p-4" uk-grid>
              <div>
                <h5 class="uk-text-bold"> Ad</h5>
                <p> {{ userData.name }} </p>
              </div>
              <div>
                <h5 class="uk-text-bold"> Soyad</h5>
                <p> {{ userData.surname }} </p>
              </div>
              <div>
                <h5 class="uk-text-bold"> Doğum Tarihim</h5>
                <p> {{ userData.dob }} </p>
              </div>
              <div>
                <h5 class="uk-text-bold"> E-posta </h5>
                <p style="overflow:hidden;text-overflow: ellipsis;"> {{ userData.email }}</p>
              </div>
              <div>
                <h5 class="uk-text-bold"> Telefon </h5>
                <p> {{ userData.phone }} </p>
              </div>
            </div>
            <hr>
            <div class="uk-child-width-1-2@s uk-grid-small p-4" uk-grid>
              <div>
                <h5 class="uk-text-bold"> Giriş Parolası </h5>
                <p> ******** </p>
              </div>
              <div>
                <h5 class="uk-text-bold"> Doğrulama Yöntemi</h5>
                <p><i class="icon-feather-check"></i> {{ user_detail && user_detail.user &&
                  user_detail.user.authentication_type ? user_detail.user.authentication_type.name : '-' }}</p>
              </div>
            </div>
          </div>
          <div class="uk-card-default rounded mt-4">
            <div class="uk-flex uk-flex-between uk-flex-middle py-3 px-4">
              <h5 class="mb-0"><i class="icon-feather-briefcase"></i> Şirket Künyesi </h5>
              <span @click="closeModal" class="uk-modal-close" uk-tooltip="title: Bilgileri Güncelle; pos: left">
                <i class="icon-feather-settings"></i>
              </span>

            </div>
            <hr class="m-0">
            <div class="uk-child-width-1-2@s uk-grid-small p-4" uk-grid v-if="user_detail">
              <div>
                <h5 class="uk-text-bold"> Şirket Adı </h5>
                <p> {{ userItem && userItem.company ? userItem.company.name : '' }}</p>
              </div>
              <div>
                <h5 class="uk-text-bold"> Görev </h5>
                <p> {{ userItem && userItem.position ? userItem.position.name : '' }}</p>
              </div>
              <div>
                <h5 class="uk-text-bold"> Unvan </h5>
                <p> {{ userItem && userItem.title ? userItem.title.name : '' }}</p>
              </div>
              <div>
                <h5 class="uk-text-bold"> GM/Bölge </h5>
                <p> {{ userItem && userItem.region ? userItem.region.name : '' }}</p>
              </div>
              <div>
                <h5 class="uk-text-bold"> Bölüm/Departman </h5>
                <p> {{ userItem && userItem.department ? userItem.department.name : '' }}</p>
              </div>
              <div>
                <h5 class="uk-text-bold"> Şube/Mağaza/Distribütör </h5>
                <p> {{ userItem && userItem.branch ? userItem.branch.name : '' }}</p>
              </div>
            </div>
          </div>
          <div class="uk-width-4-4@m mt-4" v-if="collections.length">
            <div class="course-grid-slider" uk-slider="finite: true">
              <div class="grid-slider-header">
                <div>
                  <h4 class="uk-text-truncate">
                    <i class="uil-notebooks"></i> Koleksiyonları
                  </h4>
                </div>
                <div class="grid-slider-header-link">
                  <a href="#" class="slide-nav-prev" uk-slider-item="previous"></a>
                  <a href="#" class="slide-nav-next" uk-slider-item="next"></a>
                </div>
              </div>
              <ul id="book-popular"
                class="uk-slider-items uk-child-width-1-2@m uk-child-width-1-3@s uk-grid-small uk-grid">
                <li v-for="collection in collections">
                  <div class="book-popular-card">
                    <img :src="collection.cover_image_link" alt="" class="cover-img">
                    <div class="book-details">
                      <a href="#">
                        <h4>{{ collection.name }}</h4>
                      </a>
                      <p>
                        <span v-if="collection.user_id == userData.id">
                          <i class="icon-feather-user"></i> Kişisel
                        </span>
                        <span v-else>
                          <i class="icon-feather-star"></i> {{ collection.user.name + ' ' + collection.user.surname }}
                        </span>
                      </p>
                    </div>
                  </div>
                </li>


                <!-- <li>
                   <a href="koleksiyon-detay.html">
                       <div class="course-card episode-card">
                           <div class="course-card-thumbnail ">
                               <span class="item-tag">1 içerik</span>
                               <img src="../assets/images/episodes/2.png">
                               <span class="play-button-trigger collection"></span>
                           </div>
                           <div class="course-card-body">
                               <h4 class="mb-0">Koleksiyon Başlığı </h4>
                           </div>
                       </div>
                   </a>
                   </li> -->
              </ul>
            </div>
          </div>
        </div>
        <div class="uk-width-2-5@m">
          <div class="table-responsive profile-table">
            <h4><i class="uil-location-arrow"></i> Kullanıcının Atandığı Eğitimler</h4>
            <table class="table align-items-center">
              <thead>
                <tr>
                  <th scope="col">{{ $t('general.training_name') }}</th>
                  <th scope="col">Tamamlama Tarihi</th>
                  <th scope="col">Durum / Puan</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody class="list" v-if="assignments.length">
                <tr v-for="assignment in assignments">
                  <th scope="row">
                    <div class="media align-items-center">
                      <div class="media-body">
                        <p class="name h6 mb-0 text-sm">{{ assignment.name }}</p>
                      </div>
                    </div>
                  </th>
                  <td>
                    <template v-if="+assignment.completed_status === 1">
                      -
                    </template>
                    <template v-else-if="+assignment.completed_status === 2">
                      {{ assignment.assignment_completed_at | formatDate }} / {{ assignment.assignment_duration }}
                      dakika
                    </template>
                    <template v-else>
                      -
                    </template>
                  </td>
                  <td>
                    <template v-if="+assignment.completed_status === 1">
                      Devam Ediyor
                    </template>
                    <template v-else-if="+assignment.completed_status === 2">
                      Tamamlandı / {{ assignment.contents_point }}
                    </template>
                    <template v-else>
                      Bekliyor
                    </template>
                  </td>
                  <td class="text-right">
                    <!-- Actions -->
                    <div class="actions ml-3">
                      <router-link :to="{ name: 'user.assignment-detail', params: { id: assignment.id } }"
                        class="btn btn-icon btn-hover btn-sm btn-circle" uk-tooltip="Eğitime Git">
                        <i class="uil-presentation"></i>
                      </router-link>
                      <router-link :to="{ name: 'company.assignments.add' }"
                        class="btn btn-icon btn-hover btn-sm btn-circle" uk-tooltip="Tekrar Ata">
                        <i class="uil-location-arrow text-success"></i>
                      </router-link>
                    </div>

                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="uk-modal-footer uk-text-right bggray">
      <button class="uk-button uk-button-default small uk-modal-close" type="button"><i class="uil-times"></i>
        {{ $t("general.close") }}</button>
    </div>

    <SendMessage v-if="sendMessageToUserId" :user-id="sendMessageToUserId"></SendMessage>
  </div>
</template>
<script>

import moment from "moment";

import SendMessage from "@/view/company/User/SendMessage";

import Vue from "vue";
Vue.filter('formatDate', function (value) {
  if (value) {
    return moment(String(value)).format('MM/DD/YYYY hh:mm')
  }
});

export default {
  name: "UserProfile",
  components: { SendMessage },
  data() {
    return {
      sendMessageToUserId: null,
    };
  },
  props: {
    item: {
      default: null,
      type: Object,
    },
    user_detail: {
      default: null,
      type: Object,
    },
  },
  computed: {
    userData() {
      let userData = this.item;
      if (userData && userData.hasOwnProperty('dob') && userData.dob) {
        userData.dob = userData.dob && userData.dob != "-" ? moment(userData.dob).format('DD/MM/YYYY') : '-';
      }
      return userData;
    },
    userItem() {
      return typeof this.user_detail.user !== "undefined" ? this.user_detail.user : {};
    },
    assignments() {
      if (typeof this.user_detail.assignments === "undefined") {
        return {}
      }
      let assignments = [];
      this.user_detail.assignments.forEach(item => {
        item.assignment_duration = Number(item.assignment_duration).toFixed(2);
        assignments.push(item)
      })

      return assignments;
    },

    collections() {
      return typeof this.user_detail.collections !== "undefined" ? this.user_detail.collections : {};
    },
    userPoint() {
      return typeof this.user_detail.user_point !== "undefined" ? this.user_detail.user_point : 0;
    },
    userCoin() {
      return typeof this.user_detail.user_coin !== "undefined" ? this.user_detail.user_coin : 0;
    },
    giftsCount() {
      return typeof this.user_detail.gifts_count !== "undefined" ? this.user_detail.gifts_count : 0;
    },
    certificatesCount() {
      return typeof this.user_detail.user?.certificatables_count !== "undefined" ? this.user_detail.user?.certificatables_count : 0;
    }
  },
  methods: {
    openMessageModal() {
      this.sendMessageToUserId = null;
      this.sendMessageToUserId = this.userData.id;
      this.$forceUpdate();
    },
    closeModal() {
      const modal = UIkit.modal("#modal-profile");
      if (modal) modal.$destroy(true);
      this.$router.push({ name: 'company.user.edit', params: { id: this.userData.id } })
    }
  },
  mounted() {
  },
  watch: {
    sendMessageToUserId(value) {
      const modal = UIkit.modal("#message_modal_user");
      if (value) {
        if (modal) modal.show();
      }
    }
  }
};
</script>
